import React, { useRef } from 'react';
import { Map } from 'immutable';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import jwtDecode from 'jwt-decode';

import HSBBClient from '@helpers/apiClient';
import { store } from '@redux/store';
import authActions from '@redux/auth/actions';
import { useEffect } from 'react';

export function getToken() {
  try {
    const accessToken = window.localStorage.getItem('access_token');
    const idToken = window.localStorage.getItem('id_token');
    const refreshToken = window.localStorage.getItem('refresh_token');
    return new Map({ accessToken, idToken, refreshToken });
  } catch (err) {
    window.localStorage.clear();
    return new Map();
  }
}

export function checkIsTokenExpired(token) {
  const decoded = jwtDecode(token);
  const appSettings = getAppSettings();
  const diff = Number(appSettings.renewTokenBefore); // seconds
  const now = new Date().getTime() / 1000;

  if (window.DEBUG_TOKEN) {
    console.log('accessToken.exp', decoded.exp);
    console.log('renewTokenBefore', appSettings.renewTokenBefore);
    console.log('now', now);
    console.log('difference', decoded.exp - diff - now);
  }

  const isExpired = decoded.exp - diff < now;
  return isExpired;
}

export function clearToken() {
  window.localStorage.removeItem('access_token');
  window.localStorage.removeItem('id_token');
  window.localStorage.removeItem('refresh_token');
  window.localStorage.removeItem('time_skew');
  window.localStorage.removeItem('session_timeout');
}

export function clearKcCallback() {
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key.slice(0, 11) === 'kc-callback') {
      window.localStorage.removeItem(key);
    }
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? 's' : '';
  };
  const number = (num) => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function generateRandomPassword() {
  const length = 8;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function getErrorMessage(error) {
  let message = error.message ? error.message : 'Something Went Wrong!';

  if (error.status && error.status !== 500 && error.data.error) {
    message = error.data.error;
  }

  const data = get(error, 'response.data');
  if (isString(data)) {
    message = data;
  } else {
    if (get(data, 'name', false) === 'ValidationError') {
      const validationDetails = get(data, 'validationObject.details');
      message = '<ul>';
      if (isArray(validationDetails)) {
        validationDetails.forEach((item) => {
          message += `<li>${item.message}</li>`;
        });
      }
      message += '</ul>';
      message = <div dangerouslySetInnerHTML={{ __html: message }} />;
    } else {
      // check if we have msg in data
      if (get(data, 'msg', false)) {
        message = data.msg;
      }
      if (get(data, 'Error', false)) {
        message = data.Error;
      }
    }
  }
  return message;
}

export const idleTimer = {
  intervalId: null,
  pause: false,

  onTimeout: () => {},

  stop: function () {
    window.clearInterval(this.intervalId);
  },

  start: function (timeout) {
    if (this.intervalId) {
      this.stop();
    }

    this.intervalId = window.setInterval(this.onTimeout, timeout);
  }
};

export const setAppSettings = async () => {
  const appSettings = await HSBBClient.getAppSettings();

  const appSettingsStringified = JSON.stringify(appSettings);
  window.localStorage.setItem('app_settings', appSettingsStringified);

  return appSettingsStringified;
};

export const getAppSettings = () => {
  let appSettings = window.localStorage.getItem('app_settings');

  const appSettingsParsed = JSON.parse(appSettings);
  return appSettingsParsed;
};

export function updateSessionTimeout() {
  const appSettings = getAppSettings();

  const timeout = Number(appSettings.userSessionTimeout); // seconds
  const now = new Date().getTime() / 1000;
  const sessionTimeout = now + timeout;

  window.localStorage.setItem('session_timeout', sessionTimeout);
}

export function startListener() {
  const sessionTimeout = window.localStorage.getItem('session_timeout');

  if (!sessionTimeout) {
    updateSessionTimeout();
  }

  window.addEventListener('keydown', updateSessionTimeout);
  window.addEventListener('mousedown', updateSessionTimeout);

  idleTimer.onTimeout = () => {
    const token = getToken();
    const now = new Date().getTime() / 1000;

    const accessToken = token.get('accessToken');
    const refreshToken = token.get('refreshToken');

    const haveTokens = accessToken && refreshToken;

    if (!haveTokens) {
      store.dispatch({
        type: authActions.REDIRECT_TO_LOGIN
      });
      return;
    }

    const sessionTimeout = window.localStorage.getItem('session_timeout');

    if (sessionTimeout < now) {
      idleTimer.stop();
      store.dispatch({
        type: authActions.LOGOUT
      });
    }
  };

  idleTimer.start(500);
}

export function stopListener() {
  window.removeEventListener('keydown', updateSessionTimeout);
  window.removeEventListener('mousedown', updateSessionTimeout);
  idleTimer.stop();
}

export function defineUserRoles(userRoles) {
  return (checkRoles) => {
    let verify = false;
    checkRoles.forEach((role) => {
      if (userRoles.includes(role)) {
        verify = true;
      }
    });
    return verify;
  };
}

export const isEmail = (str) => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(str);
};

export const directDownload = (url) => {
  const link = document.createElement('a');
  link.href = url;

  link.style = 'visibility:hidden';

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

export const returnDifferenceKeys = (obj1, obj2) => {
  const differenceKeys = [];

  Object.keys(obj1).forEach((key) => {
    if (!obj1[key] || !obj2[key]) {
      return;
    }

    if (obj1[key] !== obj2[key]) {
      differenceKeys.push(key);
    }
  });

  return differenceKeys;
};

export const removeUndefined = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const modifyLocalPhone = (phone) => {
  let modifiedPhone;

  if (phone && !(phone.startsWith('6') || phone.startsWith('+6'))) {
    modifiedPhone = `6${phone}`;
  }

  if (phone && phone.startsWith('+6')) {
    modifiedPhone = phone.slice(1);
  }

  return modifiedPhone || phone;
};
