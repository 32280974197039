export default {
  apiUrl: process.env.REACT_APP_API_URL
};

const siteConfig = {
  siteName: 'HSBB',
  siteIcon: 'wifi',
  footerText: 'HSBB back office'
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};

const keyCloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
  credentials: { secret: process.env.REACT_APP_KEYCLOAK_SECRET }
};

const language = 'english';

export { siteConfig, language, themeConfig, keyCloakConfig };
