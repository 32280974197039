import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux';
import App from '@containers/App/App';
import asyncComponent from '@helpers/AsyncFunc';
import loading from '@img/loading.svg';

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white'
};

const AppWrappedRoute = ({ isLoggedIn, appStatus, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isLoggedIn) {
        return <App {...props} />;
      }
      if (appStatus === 'checkingAuth') {
        return (
          <div style={style}>
            <img src={loading} alt="loading" />
          </div>
        );
      }
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);

function PublicRoutes({ history }) {
  const isLoggedIn = useSelector((state) => state.Auth.user !== null);
  const appStatus = useSelector((state) => state.Auth.appStatus);
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={'/'} component={asyncComponent(() => import('@page/auth/signin'))} />
        <Route exact path={'/signin'} component={asyncComponent(() => import('@page/auth/signin'))} />
        <Route path="/callback_kc" component={asyncComponent(() => import('@page/auth/callback_kc'))} />
        <AppWrappedRoute appType="backoffice" path="/admin" isLoggedIn={isLoggedIn} appStatus={appStatus} />
        <Route path="/unauthorized" component={asyncComponent(() => import('./containers/Page/common/unauthorized'))} />
        <Route path="*" component={asyncComponent(() => import('./containers/Page/common/404'))} />
      </Switch>
    </ConnectedRouter>
  );
}

export default PublicRoutes;
