import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'antd';
import moment from 'moment-timezone';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import appActions from '@redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import themes from '@settings/themes';
import { themeConfig, siteConfig } from '@settings';
import HSBBClient from '@helpers/apiClient';
import AppHolder from './commonStyle';
import './global.css';
const { Content, Footer } = Layout;
const { toggleAll } = appActions;

moment.tz.setDefault(process.env.RECT_APP_TIMEZONE);

function App(props) {
  const [backendVersion, setBackendVersion] = useState(null);
  const dispatch = useDispatch();
  const height = useSelector((state) => state.App.height);
  const { url } = props.match;
  const appHeight = window.innerHeight;
  useEffect(() => {
    async function fetchData() {
      try {
        const versionData = await HSBBClient.getVersion();
        // compare version with current frontend
        if (process.env.REACT_APP_VERSION !== versionData.frontend_version) {
          // check version reload counter
          let currentReloadCount = window.localStorage.getItem('version_reload_counter');
          currentReloadCount = parseInt(currentReloadCount);
          if (isNaN(currentReloadCount)) {
            currentReloadCount = 0;
          }
          window.localStorage.setItem('version_reload_counter', currentReloadCount + 1);
          if (currentReloadCount < 1) {
            window.location.reload();
          } else {
            window.alert(
              'Please clear your cache (Ctrl + F5) to ensure that you are on the latest version of HSBB BO. If any issues persist, please contact hsbb_devops@digi.com.my.'
            );
          }
        } else {
          window.localStorage.removeItem('version_reload_counter');
        }
        setBackendVersion(versionData.backend_version);
      } catch (e) {
        setBackendVersion(null);
      }
    }
    fetchData();
  }, []);
  return (
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <AppHolder>
        <Layout style={{ height: appHeight }}>
          <WindowResizeListener
            onResize={(windowSize) => dispatch(toggleAll(windowSize.windowWidth, windowSize.windowHeight))}
          />
          <Topbar url={url} />
          <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
            <Sidebar url={url} />
            <Layout
              className="isoContentMainLayout"
              style={{
                height: height
              }}
            >
              <Content
                className="isomorphicContent"
                style={{
                  padding: '70px 0 0',
                  flexShrink: '0',
                  background: '#f1f3f6',
                  position: 'relative'
                }}
              >
                <AppRouter url={url} />
              </Content>
              <Footer
                style={{
                  background: '#ffffff',
                  textAlign: 'center',
                  borderTop: '1px solid #ededed'
                }}
              >
                {siteConfig.footerText} - Frontend ({process.env.REACT_APP_VERSION}){' '}
                {backendVersion && <span>/ Backend ({backendVersion})</span>}
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </AppHolder>
    </ThemeProvider>
  );
}

export default App;
