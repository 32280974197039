const actions = {
  REDIRECT: 'REDIRECT',
  REDIRECT_TO_LOGIN: 'REDIRECT_TO_LOGIN',
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  USER_PROFILE_LOADED: 'USER_PROFILE_LOADED',
  HANDLE_AUTHENTICATION_CALLBACK: 'HANDLE_AUTHENTICATION_CALLBACK',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  handleAuthenticationCallback: () => ({
    type: actions.HANDLE_AUTHENTICATION_CALLBACK
  }),

  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION
  }),

  login: () => ({
    type: actions.LOGIN
  }),

  logout: () => ({
    type: actions.LOGOUT
  }),

  redirect: (link) => ({
    type: actions.REDIRECT,
    link
  }),

  redirectToLogin: () => ({
    type: actions.REDIRECT_TO_LOGIN
  })
};

export default actions;
