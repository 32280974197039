import styled from 'styled-components';
import { palette, font } from 'styled-theme';

const DashAppHolder = styled.div`
  font-family: ${font('primary', 0)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }
  .historyBar {
    margin-top: 20px;
  }
  .chat-block {
    display: table;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .avatar-block {
    vertical-align: top;
    display: table-cell;
    padding-right: 7px;
  }
  .msg-block {
    vertical-align: top;
    display: table-cell;
  }
  .msg-block .chat-bubble {
    border-radius: 5px;
    background: #1890ff;
    color: #fff;
    padding: 5px 10px;
  }
  .msg-block .custom-msg {
    background: rgb(26, 170, 85);
  }
  .msg-block .chat-meta {
    font-size: 10px;
    color: #2d3446;
  }
  ul.multipleFieldHistory {
    padding-left: 20px;
  }
  .appBoxContainer {
    overflow: hidden;
  }
  .appBoxContainer .statusBoxContainer {
    float: right;
  }
  .appBoxContainer .infoBox,
  .appBoxContainer .statusBox {
    float: left;
    width: 250px;
    border: 1px solid #ccc;
    padding: 15px;
    text-align: center;
    margin-right: 50px;
  }
  .appBoxContainer .statusBox {
    float: none;
    margin-bottom: 10px;
    width: 150px;
    padding: 5px;
  }
  .createApplicationForm .ant-list-split .ant-list-item:last-child {
    overflow: hidden;
  }
  .ant-form-item-label {
    line-height: inherit;
  }
  .ant-btn-block {
    margin-bottom: 10px;
  }
  .displayTable td {
    border-width: 2px;
    border-color: #000;
    border-style: solid;
    padding: 5px;
  }
`;

export default DashAppHolder;
