import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Icon } from 'antd';
import Popover from '@components/uielements/popover';
import IntlMessages from '@components/utility/intlMessages';
import authAction from '@redux/auth/actions';
import appAction from '@redux/app/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';

const { logout } = authAction;
const { closeAll } = appAction;

function TopbarUser(props) {
  const userSetting = useSelector((state) => state.Auth.userSetting);
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);
  const { user } = props;
  const handleVisibleChange = () => {
    setVisibility(!visibility);
  };
  let userAvatar = '';
  if (user) {   
    if (user.picture) {
      userAvatar = <Avatar src={user.picture} />;
    } 
    else {
      userAvatar = <Icon type="user" />
    }
  }
  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      <div className='userProfile'>
        <h4>User Profile </h4>
        Username: <br />
        <ul>
          <li className='yellowBullet'>{userSetting.userId}</li>
          <li className='blueBullet'>{userSetting.pulse_user_id}</li>
        </ul>
        
        Store ID: <br />
        <ul>
          <li className='yellowBullet'>{userSetting.storeId}</li>
          <li className='blueBullet'>{userSetting.celcom_store_id}</li>
        </ul>
      </div>

      <a
        href="#logout"
        className="isoDropdownLink"
        onClick={() => {
          dispatch(logout());
          dispatch(closeAll());
        }}
      >
        <IntlMessages id="topbar.logout" />
      </a>
    </TopbarDropdownWrapper>
  );
  return (
    <Popover
      content={content}
      trigger="click"
      visible={visibility}
      onVisibleChange={handleVisibleChange}
      arrowPointAtCenter
      placement="bottomLeft"
    >
      <div className="isoImgWrapper">{userAvatar}</div>
    </Popover>
  );
}

export default TopbarUser;
