import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import axios from 'axios';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import authActions from '@redux/auth/actions';
import reducers from '../redux/reducers';
import rootSaga from '../redux/sagas';
import { message } from 'antd';

const { logout, redirect } = authActions;

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers
  });

const history = createHistory({ basename: process.env.REACT_APP_BASENAME });
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const store = createStore(createRootReducer(history), compose(applyMiddleware(...middlewares)));
sagaMiddleware.run(rootSaga);

// logout if unauthorized error
const { dispatch } = store;
const UNAUTHORIZED = 401;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      if (status === UNAUTHORIZED) {
        if (['User not found'].indexOf(data) !== -1) {
          return dispatch(redirect(`/admin`));
        } else {
          return Promise.reject(error);
        }
      } else if (status === 400 && error.response.config.url.includes('/token/refresh')) {
        setTimeout(() => {
          dispatch(logout());
        }, 1500);
      }
    } else {
      message.error(error.message || 'Something went wrong');
    }
    return Promise.reject(error);
  }
);

export { store, history };
