import Keycloak from 'keycloak-js';
import { keyCloakConfig } from '@settings';

const kc = Keycloak(keyCloakConfig);

kc.onAuthSuccess = () => {
  window.localStorage.setItem('id_token', kc.idToken);
  window.localStorage.setItem('access_token', kc.token);
  window.localStorage.setItem('refresh_token', kc.refreshToken);
  window.localStorage.setItem('time_skew', kc.timeSkew || 0);
};

export async function loadKc() {
  const idToken = window.localStorage.getItem('id_token');
  const accessToken = window.localStorage.getItem('access_token');
  const refreshToken = window.localStorage.getItem('refresh_token');
  const timeSkew = window.localStorage.getItem('time_skew');

  if (typeof kc.authenticated === 'undefined') {
    const initOptions = { promiseType: 'native' };
    const haveTokens = idToken && accessToken && refreshToken;

    if (haveTokens) {
      initOptions.idToken = idToken;
      initOptions.token = accessToken;
      initOptions.refreshToken = refreshToken;
      initOptions.timeSkew = timeSkew;
    }

    await kc.init(initOptions);
  }
}

export async function signOut() {
  await loadKc();
  return kc.logout();
}

export async function getUserProfile() {
  await loadKc();
  const userProfile = await kc.loadUserInfo();
  return userProfile;
}

export default kc;
